/*
 * Bones Scripts File
 * Author: Eddie Machado
 *
 * This file should contain any js scripts you want to add to the site.
 * Instead of calling it in the header or throwing it inside wp_head()
 * this file will be called automatically in the footer so as not to
 * slow the page load.
 *
 * There are a lot of example functions and tools in here. If you don't
 * need any of it, just remove it. They are meant to be helpers and are
 * not required. It's your world baby, you can do whatever you want.
 */


/*
 * Get Viewport Dimensions
 * returns object with viewport dimensions to match css in width and height properties
 * ( source: http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript )
 */
function updateViewportDimensions() {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: x,
    height: y
  };
}
// setting the viewport width
var viewport = updateViewportDimensions();


/*
 * Throttle Resize-triggered Events
 * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
 * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
 */
var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout(timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

// how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
var timeToWaitForLast = 100;


/*
 * Here's an example so you can see how we're using the above function
 *
 * This is commented out so it won't work, but you can copy it and
 * remove the comments.
 *
 *
 *
 * If we want to only do it on a certain page, we can setup checks so we do it
 * as efficient as possible.
 *
 * if( typeof is_home === "undefined" ) var is_home = $('body').hasClass('home');
 *
 * This once checks to see if you're on the home page based on the body class
 * We can then use that check to perform actions on the home page only
 *
 * When the window is resized, we perform this function
 * $(window).resize(function () {
 *
 *    // if we're on the home page, we wait the set amount (in function above) then fire the function
 *    if( is_home ) { waitForFinalEvent( function() {
 *
 *	// update the viewport, in case the window size has changed
 *	viewport = updateViewportDimensions();
 *
 *      // if we're above or equal to 768 fire this off
 *      if( viewport.width >= 768 ) {
 *        console.log('On home page and window sized to 768 width or more.');
 *      } else {
 *        // otherwise, let's do this instead
 *        console.log('Not on home page, or window sized to less than 768.');
 *      }
 *
 *    }, timeToWaitForLast, "your-function-identifier-string"); }
 * });
 *
 * Pretty cool huh? You can create functions like this to conditionally load
 * content and other stuff dependent on the viewport.
 * Remember that mobile devices and javascript aren't the best of friends.
 * Keep it light and always make sure the larger viewports are doing the heavy lifting.
 *
 */

/*
 * We're going to swap out the gravatars.
 * In the functions.php file, you can see we're not loading the gravatar
 * images on mobile to save bandwidth. Once we hit an acceptable viewport
 * then we can swap out those images since they are located in a data attribute.
 */
function loadGravatars() {
  // set the viewport using the function above
  viewport = updateViewportDimensions();
  // if the viewport is tablet or larger, we load in the gravatars
  if (viewport.width >= 768) {
    jQuery('.comment img[data-gravatar]').each(function () {
      jQuery(this).attr('src', jQuery(this).attr('data-gravatar'));
    });
  }
} // end function



/*
 * Put all your regular jQuery in here.
 */
jQuery(document).ready(function ($) {

    $('.inline').modaal({
      content_source: '#inline'
    });

    $(window).load(function() {
      // Animate loader off screen
      // $(".se-pre-con").fadeOut("slow");
    });

    if ($(window).width() < 768) {
      $('.menu-item-has-children >a').click(function (e) {
        e.preventDefault();
      });
    }
    if ($(window).width() < 768) {
      $('.menu-item-has-children').click(function () {
        $(this).find('.sub-menu').slideToggle();
        $(this).toggleClass('dropped-down');
      });
    }
    if ($(window).width() < 768) {
      // menu burger déroulant
      $('#burger').click(function () {

        $(this).toggleClass('is-active');
        if ($('#inner-header').css('display') != 'none') {
          $('#inner-header').slideToggle();
        } else {
          $('#inner-header').slideToggle();
        }

      }); ////////////////////////////////////
    }
    if ($(window).width() < 768) {
      $('#inner-header .sub-menu .menu-item a').click(function () {
        $('#burger').toggleClass('is-active');
        $('#inner-header').slideToggle();
      });
    }

  // form input/label animation
  // source : https://codepen.io/nathanlong/pen/kkLKrL
  // adding/removing class .focused when we refresh the page
  $('input, textarea').each(function () {
    var inputValue = $(this).val();
    if (inputValue == "") {
      $(this).parents('.gfield').removeClass('focused');
    } else {
      $(this).parents('.gfield').addClass('focused');
    }
  });

    // adding class .focused when we click on the input
    $('input, textarea').focus(function () {
      $(this).parents('.gfield').addClass('focused');
    });

  // removing class .focused when we go out from input area
  $('input, textarea').blur(function () {
    var inputValue = $(this).val();
    if (inputValue == "") {
      $(this).parents('.gfield').removeClass('focused');
    } else {
      // $(this).addClass('filled');
    }
  }); ///////////////////////////////////

  // if ($(".modal .active")[0]){
  //   // Do something if class exists
  //   console.log('open');
  // } else {
  //   // Do something if class does not exist
  //   console.log('closed');
  // }

  $('.close').click( function() {
		console.log($(this).siblings('iframe'));
		$(this).siblings('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
	});


  // form file upload - wrapping it in new dom element
   $("li.file_upload").wrapAll("<div id='file_upload' />");


  // tooltip on the map of france
  // http://iamceege.github.io/tooltipster/
  jQuery('.mf_region a').tooltipster({
    functionPosition: function (instance, helper, position) {
      position.coord.top += 30;
      position.coord.left += 30;
      return position;
    }
  });

  // map of france - creating array with all the departments
  // making active effect on the selected department

  var allDepart = [
    "grand-est",
    "nouvelle-aquitaine",
    "bourgogne-franche-comte",
    "bretagne",
    "centre-val-de-loire",
    "ile-de-france",
    "occitanie",
    "hauts-de-france",
    "normandie",
    "pays-de-la-loire",
    "provence-alpes-cote-dazur",
    "auvergne-rhone-alpes"
  ];
  var term = "";
  var termBodyClass = "";
  var i = 0;

  while (i < allDepart.length) {

    term = allDepart[i];
    termBodyClass = "term-" + allDepart[i];
    var elementExist = document.getElementsByClassName(termBodyClass);

    if (Object.keys(elementExist).length !== 0 && elementExist.constructor !== Object) {

      document.getElementById(term).setAttribute("class", "depart_active");

      i = allDepart.length + 1;
    } else {

      i++;
    }
  }

  /*
   * Let's fire off the gravatar function
   * You can remove this if you don't need it
   */
  loadGravatars();

  var scroll = new SmoothScroll('a[href*="#"]', {
    offset: 35,
    speed: 900,
    speedAsDuration: true
  });

}); /* end of as page load scripts */