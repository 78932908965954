jQuery(document).ready(function ($) {
  if (viewport.width >= 1030) {
        // INIT ScrollMagic
        var controller = new ScrollMagic.Controller();

        /******************************************/
        /*                 HOME PAGE              */
        /******************************************/
        /*

        0. TIME LINE FRONT PAGE

        */

      var mainHeader = $('#main_header');
      var headerPageTitle = $('.top-banner h1');
      var homeTopBanner = $('.top-banner');
      var homeMain = $('#main');


      $(window).load(function(){
        $(".se-pre-con").fadeOut("slow");

        var pageLoaderTl = new TimelineMax();

            pageLoaderTl
            .fromTo(mainHeader, 0.35, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})
            .fromTo(homeTopBanner, 0.35, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})
            .fromTo(headerPageTitle, 0.75, {x: 10, autoAlpha: 0, ease:Power1.easeIn}, {x: 0, autoAlpha: 1, ease:Power1.easeIn})
            .fromTo(homeMain, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn}, "-=0.5")
            ;
      });

      /*

      1.1 HOME - SECTION Notre vision

      */
      $('section.home_intro').each(function(){
          var homeIntro = $(this);
          var homeIntroRedNumbers = $(this).find('.red_numbers ul li');
          var homeIntroTgContentIntroduction = $(this).find('.tg_content #home_introduction');
          var homeIntroTgContentIntroductionLogo = $(this).find('.tg_content .home_logo');
          var homeIntroTgContentIntroductionBtn = $(this).find('.tg_content .btn');
          var homeIntroTgDecoration = $(this).find('.tg_decoration span');

          var sectionHomeIntro = new TimelineMax();

          sectionHomeIntro
          .fromTo(homeIntro, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn})
          .staggerFromTo(homeIntroRedNumbers, 0.35, {y: 10, autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1, "animGo")
          .fromTo(homeIntroTgContentIntroduction, 0.35, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn}, "animGo")
          .staggerFromTo(homeIntroTgDecoration, 0.35, {x: -15, autoAlpha: 0, ease:Power1.easeIn}, {x: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1)
          .fromTo(homeIntroTgContentIntroductionLogo, 0.35, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})
          .fromTo(homeIntroTgContentIntroductionBtn, 0.35, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})
        ;
        var sectionHomeIntroScene = new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.95,
          reverse: false
        })
          .setTween(sectionHomeIntro)
          .addTo(controller);
      });

      /*

      1.2 HOME - SECTION section Home three activities

      */
      $('section.home_three_activities').each(function(){
        var homeThreeActivities = $(this);
        var homeThreeActivitiesTitleBtn = $(this).find('.title_btn');
        var homeThreeActivitiesTgContentIntroduction = $(this).find('.activities ul li');

        var sectionhomeThreeActivities = new TimelineMax();

        sectionhomeThreeActivities
        .fromTo(homeThreeActivities, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn})
        .fromTo(homeThreeActivitiesTitleBtn, 0.35, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})
        .staggerFromTo(homeThreeActivitiesTgContentIntroduction, 0.35, {y: 10, autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1)


        ;
        var sectionThreeActivitiesScene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.95,
        reverse: false
        })
        .setTween(sectionhomeThreeActivities)
        .addTo(controller);
      });

      /*

      1.3 HOME - SECTION section Home Job Offers

      */
    $('section.home_job_offers').each(function(){
      var homeJobOffers = $(this);
      var homeJobOffersRedColumns = $(this).find('.red_up_two_columns');
      var homeJobOffersOffersLoop = $(this).find('.offers ul.offers_loop li, .btn_wrap_offers .btn_see_more');
      var homeJobOffersRedUp = $(this).find('.red_up h2, .red_up p, .red_up .btn');

      var sectionhomeJobOffers = new TimelineMax();

      sectionhomeJobOffers
      .fromTo(homeJobOffers, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn})
      .fromTo(homeJobOffersRedColumns, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn})
      .staggerFromTo(homeJobOffersOffersLoop, 0.35, {y: 10, autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1, "animGo")
      .staggerFromTo(homeJobOffersRedUp, 0.35, {y: 10, autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1, "animGo")


      ;
      var sectionhomeJobOffersScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      reverse: false
      })
      .setTween(sectionhomeJobOffers)
      .addTo(controller);
    });

    /*

    1.4 HOME - SECTION section Home Last Article

    */
    $('section.home_last_article').each(function(){
      var homeLastArticle = $(this);
      var homeLastArticleOffers = $(this).find('.offers');
      var homeLastArticleHead = $(this).find('.home_article_head');
      var homeLastArticleArticles = $(this).find('.h_articles_sticky_last ul li');

      var sectionhomeLastArticle = new TimelineMax();

      sectionhomeLastArticle
      .fromTo(homeLastArticle, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn})
      .fromTo(homeLastArticleHead, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, "+=0.5")
      .staggerFromTo(homeLastArticleArticles, 0.35, {y: 10, autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1)


      ;
      var sectionhomeLastArticleScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      reverse: false
      })
      .setTween(sectionhomeLastArticle)
      .addTo(controller);
    });

    /*

    1.0 FOOTER - footer top

    */
    $('footer').each(function(){
      var footerTop = $(this);
      var footerTopBlocks = $(this).find('#footer-blocks nav ul li');
      var footerTopFBlock = $(this).find('#footer-middle .f_block');
      var footerTopFooterMl = $(this).find('#footer-ml');

      var sectionfooterTop = new TimelineMax();

      sectionfooterTop
      .fromTo(footerTop, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})
      .staggerFromTo(footerTopBlocks, 0.35, {x: 10, autoAlpha: 0, ease:Power1.easeIn}, {x: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1)
      .staggerFromTo(footerTopFBlock, 0.35, {y: 10, autoAlpha: 0, ease:Power1.easeIn}, {y: 0, autoAlpha: 1, ease:Power1.easeIn}, 0.1)
      .fromTo(footerTopFooterMl, 0.3, {autoAlpha: 0, ease:Power1.easeIn}, {autoAlpha: 1, ease:Power1.easeIn})


      ;
      var sectionfooterTopScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      reverse: false
      })
      .setTween(sectionfooterTop)
      // .addIndicators({name: "pin scene", colorEnd: "#FFFFFF"})
      .addTo(controller);
    });
  }
});